import React from 'react';
import Install from '../../assets/images/Install.png';
import Hosts from '../../assets/images/Hosts.png';
import Choose from '../../assets/images/Choose.png';
import Randomization from '../../assets/images/Randomization.png';
import Winner from '../../assets/images/Winner.png';

import './index.sass';

function Player() {
  return (
    <div className="player">
      <section className="player__banner">
        <h4>FAIR AND SQUARE</h4>
        <p>
          10x10 takes the guess work out of pools by automatically generating the pool numbers at
          random.
        </p>
        <button>DOWNLOAD</button>
      </section>
      <section className="player__howto">
        <h3 className="player__title">HOW TO HOST A POOL</h3>
        <div className="player__video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/kImTwXVGcnI"
            title="Register and Play 10x10"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="player__step">
          <div className="item">
            <div className="image">
              <img src={Install} alt="" />
            </div>
            <div className="des">
              <h5>INSTALL</h5>
              <p>Download the 10x10 mobile app and create your login.</p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Hosts} alt="" />
            </div>
            <div className="des">
              <h5>HOST</h5>
              <p>
                Ensure you have enough credits to host a game or purchase new credits via the app.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Choose} alt="" />
            </div>
            <div className="des">
              <h5>NEW POOL</h5>
              <p>Fill in your pool information such as the maximum number of bets and prizes.</p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Randomization} alt="" />
            </div>
            <div className="des">
              <h5>RANDOMIZATION</h5>
              <p>
                After all players have chosen their squares, 10X10’s leading algorithmic technology
                will generate numbers to each column and row at random.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Winner} alt="" />
            </div>
            <div className="des">
              <h5>GAME TIME</h5>
              <p>
                Access your pools real time. The winner is the box that contains the last digit of
                each team’s score. The winner will automatically receive an email notification of
                their winning box.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Player;
