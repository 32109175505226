import React, { useState, useEffect } from 'react';
import { ReactComponent as Arrow } from '../../assets/images/Arrow.svg';

import Icon from '../Icon.js';
import { Dropdown, Space, Empty } from 'antd';
import Modal from '../../components/Modal';
import './index.sass';

import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import api from '../../utils/axios.js';
import smoothscroll from 'smoothscroll-polyfill';
import { gql, useQuery } from 'urql';
import {
  SingleEliminationBracket,
  DoubleEliminationBracket,
  Match,
  MATCH_STATES,
  SVGViewer,
  createTheme,
} from 'react-tournament-brackets';
// import { useWindowSize } from '@uidotdev/usehooks';
import playoff from './playoff';

const poolsWrapperRef = React.createRef();
const poolsRef = React.createRef();

const GamesQuery = gql`
  query GetPools {
    games {
      id
      away {
        name
      }
      home {
        name
      }
      datetime
    }
    pools {
      title
      game {
        type
        season
        away {
          name
        }
        home {
          name
        }
      }
    }
  }
`;

export default function Pools() {
  // const [width, height] = useWindowSize();
  const [typeOptions, setTypeOptions] = useState([]);
  const [type, setType] = useState({ key: null, label: 'GAME EVENT' });
  const [game, setGame] = useState({ key: null, label: 'ALL' });
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [isPoolShow, setIsPoolShow] = useState(false);
  const [pool, setPool] = useState({
    title: 'NYC VS WAS',
    qrcodeURL:
      'https://tenxten.app.link?mode=host&action=create-pool&game-type=nfl&game-season-type=championship',
    hostName: 'Da Man',
    joinCode: '1025870196',
  });

  useEffect(() => {
    // kick off the polyfill!
    smoothscroll.polyfill();
    window.__forceSmoothScrollPolyfill__ = true;

    fetchGameType();
  }, []);

  const [result, reexecuteQuery] = useQuery({
    query: GamesQuery,
  });

  async function fetchGameType() {
    // try {
    //   const res = await api.get('/api/game/types');
    //   console.log('/api/game/types', res);
    //   const options = res.objects.map((option) => ({
    //     key: option.type,
    //     label: option.type,
    //   }));
    //   options.unshift({
    //     key: null,
    //     label: 'GAME EVENT',
    //   });
    //   setTypeOptions(options);
    // } catch (err) {
    //   console.warn('fetchGameType', err);
    // }
    setTypeOptions([
      // { key: 'tmp-0', label: 'GAME EVENT' },
      { key: 'NBA', label: 'NBA' },
      { key: 'NBA-PLAYOFFS', label: 'NBA PLAYOFFS' },
      { key: 'NFL', label: 'NFL' },
      { key: 'NFL-CHAMPIONSHIP', label: 'Superbowl' },
    ]);
  }

  // async function fetchGames(type) {
  // try {
  //   const params = { type };
  //   const res = await api.get('/api/game', { params });
  //   let options = [];
  //   res.objects.forEach((option) => {
  //     if (+new Date(option.game_datetime) > Date.now()) {
  //       options.push({
  //         key: option._id,
  //         label: `${option.home.name} vs ${option.away.name}`,
  //       });
  //     }
  //   });
  //   options.unshift({
  //     key: null,
  //     label: 'ALL',
  //   });
  //   setGameOptions(options);
  // } catch (err) {
  //   console.warn('fetchGames', err);
  // }
  // console.log(`[fetchGames] type=${type}`);
  //   setGameOptions([]);
  // }

  async function onClickType({ key, domEvent }) {
    if (key === 'tmp-0') {
      setType({ key: null, label: 'GAME EVENT' });
      reexecuteQuery();
    } else {
      setType({ key, label: domEvent.target.firstChild.data });
      // Promise.all([fetchPools(key, null, true), fetchGames(key)]);
    }
  }

  async function onClickGame({ key, domEvent }) {
    if (key === 'tmp-0') {
      setGame({ key: null, label: 'ALL' });
      reexecuteQuery();
    } else {
      setGame({ key, label: domEvent.target.firstChild.data });
      reexecuteQuery();
    }
  }

  function selectPool(game) {
    const { home, away } = game.game;
    setPool({
      title: `${home.name} VS ${away.name}`,
      qrcodeURL:
        'https://tenxten.app.link?mode=host&action=create-pool&game-type=nfl&game-season-type=championship',
      hostName: game.host_name,
      joinCode: game.join_code,
    });
    setIsPoolShow(true);
  }

  function prev() {
    const scrollWidth = poolsWrapperRef.current.scrollWidth;
    const scrollLeft = poolsWrapperRef.current.scrollLeft;
    const offsetWidth = poolsWrapperRef.current.offsetWidth;
    const scrollPosition = scrollLeft + offsetWidth;
    if (scrollPosition <= scrollWidth) {
      poolsWrapperRef.current.scrollTo(scrollLeft - offsetWidth + 163, 0);
    } else {
      setPrevDisabled(true);
    }
  }

  function next() {
    const scrollWidth = poolsWrapperRef.current.scrollWidth;
    const scrollLeft = poolsWrapperRef.current.scrollLeft;
    const offsetWidth = poolsWrapperRef.current.offsetWidth;
    const scrollPosition = scrollLeft + offsetWidth;
    if (scrollPosition <= scrollWidth) {
      poolsWrapperRef.current.scrollTo(scrollLeft + offsetWidth - 163, 0);
    } else {
      setNextDisabled(true);
    }
  }

  const menuDOM = (
    <section className="pools__menu">
      <Dropdown
        menu={{ items: typeOptions, onClick: onClickType }}
        trigger={['click']}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space className="dropdown__title">
            {type.label}
            <Arrow />
          </Space>
        </a>
      </Dropdown>
      <Dropdown
        menu={{
          items: result.data?.games.map((g) => ({
            label: `${g.away.name} vs ${g.home.name}`,
            key: g.id,
          })),
          onClick: onClickGame,
        }}
        trigger={['click']}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space className="dropdown__title">
            {game.label}
            <Arrow />
          </Space>
        </a>
      </Dropdown>
    </section>
  );

  const gameDOM = (games) => {
    return games.map((game) => (
      <div className="game" key={uuidv4()} onClick={() => selectPool(game)}>
        <div className="time">
          {dayjs(game.bet_end_datetime).format('D MMM h:mm A')}
        </div>
        <div className="host">{`${game.host_name} - ${game.join_code}`}</div>
        <div className="team">
          <Icon icon="NFL" color="white" />
          <p className="name">{game.game.home.name}</p>
        </div>
        <div className="team">
          {/* <img src={game.game.away.team.logo_url} alt="" /> */}
          <Icon icon="NFL" color="white" />
          <p className="name">{game.game.away.name}</p>
        </div>
      </div>
    ));
  };

  const poolMap = () =>
    (result.data?.pools || []).reduce((prev, cur) => {
      (prev[cur.game.type] = prev[cur.game.type] || []).push(cur);
      return prev;
    }, {});

  const leagueDOM = Object.keys(poolMap()).map((key) => {
    if (!poolMap()[key].length) return;
    else {
      return (
        <div className="league" key={key}>
          <div className="logo">
            <Icon size={50} color="white" icon={key} />
          </div>
          <div className="games">{gameDOM(poolMap()[key])}</div>
        </div>
      );
    }
  });

  // const finalWidth = Math.max(width - 50, 500);
  // const finalHeight = Math.max(height - 100, 500);
  function isPoolsAvalabel() {
    // const hasPools = !Object.keys(pools).some(key => Boolean(pools[key].length));
    if (searchEmpty) {
      return (
        <div className="pools">
          {menuDOM}
          <section className="pools__list" ref={poolsWrapperRef}>
            <Empty description={<span>No available pools</span>}></Empty>
          </section>
        </div>
      );
    } else {
      // https://cdn.nba.com/static/json/staticData/brackets/2023/PlayoffBracket.json
      const mapped = playoff.bracket.playoffBracketSeries.map((p) => ({
        id: p.seriesId.slice(0, 12),
        nextMatchId: playoff.bracket.playoffBracketSeries
          .find((nextP) => {
            const matched = [nextP.highSeedId, nextP.lowSeedId].filter(
              (value) => [p.highSeedId, p.lowSeedId].includes(value),
            );
            return nextP.roundNumber > p.roundNumber && matched.length > 0;
          })
          ?.seriesId.slice(0, 12),
        tournamentRoundText: p.poRoundDesc,
        startTime: '',
        state: 'SCHEDULED',
        participants: [
          {
            id: p.highSeedId,
            resultText: null,
            isWinner: p.highSeedSeriesWins > p.lowSeedSeriesWins,
            status: null,
            name: p.highSeedName,
            picture: null,
          },
          {
            id: p.lowSeedId,
            resultText: null,
            isWinner: p.highSeedSeriesWins < p.lowSeedSeriesWins,
            status: null,
            name: p.lowSeedName,
            picture: null,
          },
        ],
      }));

      const WhiteTheme = createTheme({
        // textColor: { main: '#000000', highlighted: '#07090D', dark: '#3E414D' },
        // matchBackground: { wonColor: '#daebf9', lostColor: '#96c6da' },
        // score: {
        //   background: { wonColor: '#87b2c4', lostColor: '#87b2c4' },
        //   text: {
        //     highlightedWonColor: '#7BF59D',
        //     highlightedLostColor: '#FB7E94',
        //   },
        // },
        // border: {
        //   color: '#CED1F2',
        //   highlightedColor: '#da96c6',
        // },
        // roundHeader: { backgroundColor: '#da96c6', fontColor: '#000' },
        // connectorColor: '#CED1F2',
        // connectorColorHighlight: '#da96c6',
        // svgBackground: '#FAFAFA',
      });

      return (
        <div className="pools">
          {/* {menuDOM} */}
          <section className="pools__list" ref={poolsWrapperRef}>
            <div className="list" ref={poolsRef}>
              {leagueDOM}

              {/* <SingleEliminationBracket
                matches={mapped}
                theme={WhiteTheme}
                options={{
                  style: {
                    // roundHeader: { backgroundColor: '#AAA' },
                    // connectorColor: '#FF8C00',
                    // connectorColorHighlight: '#000',
                  },
                }}
                // matchComponent={Match}
                matchComponent={({
                  match,
                  onMatchClick,
                  onPartyClick,
                  onMouseEnter,
                  onMouseLeave,
                  topParty,
                  bottomParty,
                  topWon,
                  bottomWon,
                  topHovered,
                  bottomHovered,
                  topText,
                  bottomText,
                  connectorColor,
                  computedStyles,
                  teamNameFallback,
                  resultFallback,
                }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <div
                      onMouseEnter={() => onMouseEnter(topParty.id)}
                      style={{ display: 'flex' }}
                    >
                      <div>{topParty.name || teamNameFallback}</div>
                      <div>
                        {topParty.resultText ?? resultFallback(topParty)}
                      </div>
                    </div>
                    <div
                      style={{
                        height: '1px',
                        width: '100%',
                      }}
                    />
                    <div
                      onMouseEnter={() => onMouseEnter(bottomParty.id)}
                      style={{ display: 'flex' }}
                    >
                      <div>{bottomParty.name || teamNameFallback}</div>
                      <div>
                        {bottomParty.resultText ?? resultFallback(topParty)}
                        <Icon icon="NFL" color="white" />
                      </div>
                    </div>
                  </div>
                )}
                // svgWrapper={({ children, ...props }) => (
                //   <SVGViewer
                //     width={poolsWrapperRef.current.scrollWidth}
                //     height={poolsWrapperRef.current.scrollHeight}
                //     {...props}
                //   >
                //     {children}
                //   </SVGViewer>
                // )}
              /> */}
            </div>
            <button
              className="controller prev"
              onClick={prev}
              disabled={prevDisabled}
            >
              <Arrow />
            </button>
            <button
              className="controller next"
              onClick={next}
              disabled={nextDisabled}
            >
              <Arrow />
            </button>
          </section>
        </div>
      );
    }
  }
  return (
    <>
      {isPoolsAvalabel()}
      <Modal
        title={pool.title}
        qrcodeURL={pool.qrcodeURL}
        des={`${pool.hostName} - ${pool.joinCode}`}
        isShow={isPoolShow}
        closeModal={() => setIsPoolShow(false)}
      />
    </>
  );
}
