import React from 'react';
import { ReactComponent as Seemore } from '../../assets/images/Seemore.svg';
import { ReactComponent as Android } from '../../assets/images/Android.svg';
import { ReactComponent as IOS } from '../../assets/images/IOS.svg';
import { ReactComponent as AndroidMobile } from '../../assets/images/Android_mobile.svg';
import { ReactComponent as IOSMobile } from '../../assets/images/IOS_mobile.svg';
import { ReactComponent as EmblemLogo } from '../../assets/images/Emblem_logo.svg';

import './index.sass';

function FAQ() {
  return (
    <div className="faq">
      <div className="faq__wrapper">
        <EmblemLogo className="faq__logo" />
        <h2>FAQ</h2>
        <section className="faq__item">
          <h4>WHAT MAKES 10X10 SPECIAL?</h4>
          <p>
            10x10 promotes efficiency, scalability, and fairness. Players choose boxes from their
            phone and can view the pool real-time from the mobile app. 10x10’s leading technology
            generates each pool’s numbers at random, so everyone can just focus on the best
            part…game time
          </p>
          <a href="./">
            See more
            <Seemore />
          </a>
        </section>
        <section className="faq__item">
          <h4>WHERE CAN I DOWNLOAD 10X10?</h4>
          <p>You can also search “10X10 sports square” in the Apple store or the Play store.</p>
          <div className="faq__download">
            <Android className="web" />
            <IOS className="web" />
            <AndroidMobile className="mobile" />
            <IOSMobile className="mobile" />
          </div>
        </section>
        <section className="faq__item">
          <h4>HOW MUCH DOES IT COST TO HOST A POOL?</h4>
          <p>
            Hosting works on a points system — 100 points cost $.99, 300 points cost $1.99, 1000
            points cost $5.99, and 2000 points cost $9.99. For NFL, it requires 100 points to host a
            pre-season pool, 300 for regular season pool, and 1000 for a playoff pool and 2000 for a
            superbowl pool. For NBA, it requires 200 points for preseason game, 500 for regular
            season game, 1000 for playoff game and 2000 for championships.
          </p>
          <a href="./">
            See more
            <Seemore />
          </a>
        </section>
        <section className="faq__item">
          <h4>HOW MUCH DOES IT COST TO PLAY IN A POOL?</h4>
          <p>
            It is free to download the 10x10 app. Please contact the pool’s host directly to discuss
            participation rules and prizes.
          </p>
          <a href="/faq/##">
            See more
            <Seemore />
          </a>
        </section>
      </div>
    </div>
  );
}

export default FAQ;
