import React from 'react';
import iPhone from '../../assets/images/iPhone.png';
import { FaBasketball as NBA, FaFootball as NFL } from 'react-icons/fa6';
import Install from '../../assets/images/Install.png';
import Hosts from '../../assets/images/Hosts.png';
import Invite from '../../assets/images/Invite.png';
import Newpool from '../../assets/images/Newpool.png';
import Randomization from '../../assets/images/Randomization.png';
import Winner from '../../assets/images/Winner.png';
import { ReactComponent as Logo } from '../../assets/images/Emblem_logo.svg';
import './index.sass';

function Host() {
  return (
    <div className="host">
      <section className="host__banner">
        <div className="host__banner-wrapper">
          <div className="intro">
            <h4>IT'S A GAME CHANGER</h4>
            <p>
              Forget the days of drawing numbers out of a hat, standing by the
              copying machine, and handing out photocopies. Keep up with the
              game and your pools all in one place using the 10x10 app.
            </p>
            <button>DOWNLOAD</button>
          </div>
          <img src={iPhone} alt="" />
        </div>
      </section>
      <section className="host__howto">
        <h3 className="host__title">HOW TO HOST A POOL</h3>
        <div className="host__video">
          <iframe
            src="https://www.youtube.com/embed/eejpgxI2j1I"
            title="10x10 sports squares - mobile Super Bowl office pool App"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="host__board">
          <NFL size={150} className="icons" />
          <div className="content">
            <div className="line">
              <p>Preseason</p>
              <p>100</p>
            </div>
            <div className="line">
              <p>Regular Season</p>
              <p>300</p>
            </div>
            <div className="line">
              <p>Playoff</p>
              <p>1,000</p>
            </div>
            <div className="line">
              <p>Super Bowd/Final</p>
              <p>2,000</p>
            </div>
          </div>
        </div>
        <div className="host__board">
          <NBA size={150} className="icons" />
          <div className="content">
            <div className="line">
              <p>Preseason</p>
              <p>100</p>
            </div>
            <div className="line">
              <p>Regular Season</p>
              <p>300</p>
            </div>
            <div className="line">
              <p>Playoff</p>
              <p>1,000</p>
            </div>
            <div className="line">
              <p>Super Bowd/Final</p>
              <p>2,000</p>
            </div>
          </div>
        </div>
        <div className="host__board">
          <Logo />
          <div className="content">
            <div className="line">
              <p>Preseason</p>
              <p>100</p>
            </div>
            <div className="line">
              <p>Regular Season</p>
              <p>300</p>
            </div>
            <div className="line">
              <p>Playoff</p>
              <p>1,000</p>
            </div>
            <div className="line">
              <p>Super Bowd/Final</p>
              <p>2,000</p>
            </div>
          </div>
        </div>
      </section>
      <section className="host__offer">
        <h3 className="host__title">New Offer!!</h3>
        <p>Subscription for</p>
        <div className="circle">3M</div>
        <div className="circle">6M</div>
        <div className="circle">12M</div>
        <div className="host__step">
          <div className="item">
            <div className="image">
              <img src={Install} alt="" />
            </div>
            <div className="des">
              <h5>INSTALL</h5>
              <p>Download the 10x10 mobile app and create your login.</p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Hosts} alt="" />
            </div>
            <div className="des">
              <h5>HOST</h5>
              <p>
                Ensure you have enough credits to host a game or purchase new
                credits via the app.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Invite} alt="" />
            </div>
            <div className="des">
              <h5>NEW POOL</h5>
              <p>
                Fill in your pool information such as the maximum number of bets
                and prizes.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Newpool} alt="" />
            </div>
            <div className="des">
              <h5>SHARE</h5>
              <p>
                Spread the word and recruit friends to join your pool by sharing
                the pool’s unique code.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Randomization} alt="" />
            </div>
            <div className="des">
              <h5>RANDOMIZATION</h5>
              <p>
                After all players have chosen their squares, 10X10’s leading
                algorithmic technology will generate numbers to each column and
                row at random.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="image">
              <img src={Winner} alt="" />
            </div>
            <div className="des">
              <h5>GAME TIME</h5>
              <p>
                Access your pools real time. The winner is the box that contains
                the last digit of each team’s score. The winner will
                automatically receive an email notification of their winning
                box.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="host__idea">
        <h3 className="host__title">POOL SUBJECT IDEA</h3>
        <p>
          10x10 caters to both NFL and NBA — for American football and
          basketball alike, try using the last digit of each teams's score as an
          indication of the winner's box
        </p>
      </section>
    </div>
  );
}

export default Host;
