import React from 'react';
import { FaBasketball as NBA, FaFootball as NFL } from 'react-icons/fa6';

const Icon = ({ icon, className, ...rest }) => {
  const icons = {
    NBA,
    NFL,
  };
  const TheIcon = icons[icon] || NBA;

  return <TheIcon {...rest} className={className} />;
};

export default Icon;
