import React, { useEffect } from 'react';
import { ReactComponent as Cross } from '../../assets/images/Cross.svg';
import { QRCodeSVG } from 'qrcode.react';

import './index.sass';

export default function Modal({ title, qrcodeURL, des, isShow = false, closeModal }) {
  useEffect(() => {
    if (isShow) document.body.classList.add('scroll-off');
    else document.body.classList.remove('scroll-off');
  }, [isShow]);

  function toGame() {
    window.open(qrcodeURL, '_blank').focus();
    closeModal();
  }

  return (
    <div className={`modal ${isShow ? '' : 'd-none'}`} onClick={closeModal}>
      <div className="modal__wrapper" onClick={e => e.stopPropagation()}>
        <div className="modal__header">
          <h4>{title}</h4>
          <Cross onClick={closeModal} />
        </div>
        <div className="modal__body">
          <div className="qrcode">
            <QRCodeSVG value={qrcodeURL} />
          </div>
          <p>{des}</p>
          <button onClick={toGame}>PLAY NOW</button>
        </div>
      </div>
    </div>
  );
}
