import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Home, Host, Player, FAQ } from '../pages';

export default (
  <Routes>
    <Route exact path="/" element={<Home />} />
    <Route path="/home" element={<Home />} />
    <Route path="/host" element={<Host />} />
    <Route path="/player" element={<Player />} />
    <Route path="/faq" element={<FAQ />} />
    {/* <Route component={NotFound} status={404} /> */}
  </Routes>
);
