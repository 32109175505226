import axios from 'axios';
const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://dev.10x10xu.com' : 'https://prod.10x10xu.com';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.response.use(
  (res) => {
    const { status, data, statusText } = res;
    if (status === 200 || status === 201) {
      if (data.success) {
        return data;
      } else {
        throw new Error(data.message);
      }
    } else {
      throw new Error(statusText);
    }
  },
  (err) => {
    const error = err?.response?.data?.error;
    return Promise.reject(error);
  },
);

export default api;
