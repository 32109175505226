import React, { useState, useEffect } from 'react';
import Pools from '../../components/Pools';
import { ReactComponent as Android } from '../../assets/images/Android.svg';
import { ReactComponent as IOS } from '../../assets/images/IOS.svg';
import Install from '../../assets/images/Install.png';
import Play from '../../assets/images/Play.png';
import Share from '../../assets/images/Share.png';
import iPhone from '../../assets/images/iPhone.png';
import './index.sass';


function Home() {
  const [notifications, setNotifications] = useState([]);
  const [animationSecs, setAnimationSecs] = useState(0);
  const ANIM_UNIT_SEC = 10;

  async function fetchNotifications() {
    //   try {
    //     const res = await api.get('api/notification/publishing');
    //     const data = [];
    //     res.data.notifications.forEach((item) => {
    //       if (item.createDatetime >= Date.now()) {
    //         data.push(item);
    //       }
    //     });
    //     const seconds = data.length * ANIM_UNIT_SEC;
    //     setAnimationSecs(seconds);
    //     setNotifications(data);
    //   } catch (err) {
    //     console.warn('fetchNotifications', err);
    //   }
    const seconds = 1 * ANIM_UNIT_SEC;
    setAnimationSecs(seconds);
    setNotifications([]);
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  function notificationsDOM() {
    if (!notifications.length) return;
    else {
      const lineDOM = notifications.map((notify) => (
        <span key={notify.id}>{notify.content}</span>
      ));
      return (
        <section className="home__marquee">
          <div
            className="marquee"
            style={{ 'animation-duration': `${animationSecs}s` }}
          >
            {lineDOM}
          </div>
        </section>
      );
    }
  }

  return (
    <div className="home">
      <section>
        <Pools />
      </section>
      <section className="home__banner">
        <div className="home__banner-wrapper">
          <div className="intro">
            <h1>10X10 - TEN BY TEN</h1>
            <h4>
              Digital Sports Pools
              <br /> - Fair and Square
            </h4>
            <p>
              10X10’s leading algorithmic technology generates pool numbers at
              random. No more photocopied papers. Access your pool real-time
              from your mobile phone.
            </p>
            <div className="download">
              <Android />
              <IOS />
            </div>
          </div>
          <img src={iPhone} alt="" />
        </div>
      </section>
      <section className="home__start">
        <h3 className="home__title">HOW TO START</h3>
        <div className="home__video">
          <iframe
            src="https://www.youtube.com/embed/P_ui9KYo-Qo"
            title="How to Register/Login for 10x10"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="home__step">
          <div className="item">
            <img src={Install} alt="" />
            <div className="des">
              <h5>Install</h5>
              <p>Download the 10x10 mobile app and create your login</p>
            </div>
          </div>
          <div className="item">
            <img src={Play} alt="" />
            <div className="des">
              <h5>Play</h5>
              <p>Choose your role as a HOST or as a PLAYER</p>
            </div>
          </div>
          <div className="item">
            <img src={Share} alt="" />
            <div className="des">
              <h5>Share</h5>
              <p>
                Spread the word and recruit friends to play by sharing the
                pool’s unique code
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home__spotlight">
        <h4>MVP SPOTLOIGHT</h4>
        <p>
          With the 10X10 mobile app, you can invite colleagues and friends with
          autogenerated text, pick your boxes right from your phone, and view
          your pool in real-time.
        </p>
        <button>PLAY NOW</button>
      </section>
      {notificationsDOM()}
    </div>
  );
}

export default Home;
