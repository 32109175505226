import React from 'react';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { ReactComponent as Facebook } from '../../assets/images/Facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/Instagram.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/LinkedIn.svg';
import { ReactComponent as Twitter } from '../../assets/images/Twitter.svg';
import { ReactComponent as Android } from '../../assets/images/Android_mobile.svg';
import { ReactComponent as IOS } from '../../assets/images/IOS_mobile.svg';
import { ReactComponent as Email } from '../../assets/images/Email.svg';
import { ReactComponent as Phone } from '../../assets/images/Phone.svg';
import { ReactComponent as Arrow } from '../../assets/images/Arrow.svg';

import './index.sass';

function Footer() {
  return (
    <div className="footer">
      <div className="footer__wrapper">
        <Logo className="footer__logo" />
        <div className="footer__intro expand">
          <label className="expand__title" htmlFor="introduction">
            <h4>INTRODUCTION</h4>
            <input id="introduction" type="checkbox" />
            <Arrow />
          </label>
          <div className="expand__content">
            <p>
              10x10's leading algorithmic technology generates pool numbers at random. No more
              photocopied papers. Access your pool real-time from you mobile phone.
            </p>
            <div className="footer__download">
              <Android />
              <IOS />
            </div>
          </div>
        </div>
        <div className="footer__contact expand">
          <label className="expand__title" htmlFor="contact">
            <h4>CONTACT INFO</h4>
            <input id="contact" type="checkbox" />
            <Arrow />
          </label>
          <div className="expand__content">
            <p>
              Whether it's a suggestion to improve the user experience, general questions, or
              technical support, we'd love to hear from you! Send us an email and we'll try to
              respond as soon as possible.
            </p>
            <div className="footer__contact-way">
              <p>
                <Email />
                spansupport@10x10xu.com
              </p>
              <p>
                <Phone />
                (516) 655-8515
              </p>
            </div>
          </div>
        </div>
        <div className="footer__social">
          <Facebook
            onClick={() => window.open('https://www.facebook.com/10x10xu', '_blank').focus()}
          />
          <Instagram
            onClick={() => window.open('https://www.instagram.com/10x10app/', '_blank').focus()}
          />
          <LinkedIn
            onClick={() =>
              window.open('https://www.linkedin.com/company/10444471/', '_blank').focus()
            }
          />
          <Twitter onClick={() => window.open('https://twitter.com/10x10app', '_blank').focus()} />
        </div>
      </div>
      <div className="footer__copyright">&copy; Copyright 10x10 All Right Reserved</div>
    </div>
  );
}

export default Footer;
