import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import routes from './routes';

import Header from './components/Header';
import Footer from './components/Footer';

import './App.sass';

import { Client, Provider, cacheExchange, fetchExchange } from 'urql';

const client = new Client({
  url: (() => {
    console.log('NODE_ENV=', process.env.NODE_ENV);
    switch (process.env.NODE_ENV) {
      case 'production':
        return 'https://prod.10x10xu.com/gql';
      case 'development':
        return 'https://dev.10x10xu.com/gql';
      default:
        return 'http://localhost:8080/gql';
    }
  })(),
  exchanges: [cacheExchange, fetchExchange],
});

// eslint-disable-next-line react/display-name
export default () => {
  return (
    <div className="App">
      <Provider value={client}>
        <Router>
          <Header />
          <main>{routes}</main>
          <Footer />
        </Router>
      </Provider>
    </div>
  );
};
