import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { ReactComponent as MenuButton } from '../../assets/images/Menu.svg';
import { ReactComponent as Cancel } from '../../assets/images/Cross.svg';
import { ReactComponent as Download } from '../../assets/images/Download.svg';
import { Link, useNavigate } from 'react-router-dom';
import './index.sass';

const menuButotn = React.createRef();

function Header() {
  const [menuShow, setMenuShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (menuShow) document.body.classList.add('scroll-off');
    else document.body.classList.remove('scroll-off');
  }, [menuShow]);

  return (
    <div className="header">
      <div className="header__wrapper">
        <label htmlFor="menu-button" className="header__menu-button">
          <input id="menu-button" type="checkbox" onChange={() => setMenuShow(!menuShow)} />
          <MenuButton className="menu" />
          <Cancel className="cancel" />
        </label>
        {/* <Link to="/" className="header__logo"></Link> */}
        <Logo className="header__logo" onClick={() => navigate('/')} />
        <nav className="header__tabs tabs">
          <Link to="/host" className="tab__item">
            HOSTS
          </Link>
          <Link to="/player" className="tab__item">
            PLAYERS
          </Link>
          <Link to="/faq" className="tab__item">
            FAQ
          </Link>
        </nav>
        <button className="header__download web" type="button">
          DOWNLOAD
        </button>
        <button className="header__download mobile" type="button">
          <Download />
        </button>
      </div>
      <nav className="header__menu">
        <Link
          to="/host"
          onClick={() => {
            menuButotn.click();
          }}
          className="tab__item"
        >
          HOSTS
        </Link>
        <Link
          to="/player"
          onClick={() => {
            menuButotn.click();
          }}
          className="tab__item"
        >
          PLAYERS
        </Link>
        <Link
          to="/faq"
          onClick={() => {
            menuButotn.click();
          }}
          className="tab__item"
        >
          FAQ
        </Link>
      </nav>
    </div>
  );
}

export default Header;
